import { __decorate } from "tslib";
import { Vue, Component, Inject } from 'vue-property-decorator';
import JobsList from '@/components/shared/JobsList.vue';
let RestoreIndexPage = class RestoreIndexPage extends Vue {
    async createRestore() {
        await this.$router.push({ name: 'restore.new' });
    }
    async showLogs(query) {
        await this.$router.push({
            name: 'restore.logs',
            query,
        });
    }
};
__decorate([
    Inject()
], RestoreIndexPage.prototype, "organizationId", void 0);
RestoreIndexPage = __decorate([
    Component({
        components: {
            JobsList,
        },
    })
], RestoreIndexPage);
export default RestoreIndexPage;
